// @generated source--
// scripts/generate-bits/file-generators/component-story-map.generator.ts

export const patternStoryMap: {
  [name: string]: {
    path: string;
    tags: string[];
    internalOnly: boolean;
    stories: string[];
  };
} = {
  'ai-button': {
    path: '',
    tags: ['other'],
    internalOnly: false,
    stories: ['default'],
  },
  'ai-modal': {
    path: '',
    tags: ['other'],
    internalOnly: false,
    stories: ['default', 'error-state'],
  },
  'ai-textarea': {
    path: '',
    tags: ['other'],
    internalOnly: false,
    stories: ['default'],
  },
  'auto-complete': {
    path: '',
    tags: ['actions', 'text', 'tag'],
    internalOnly: false,
    stories: ['default', 'user-mention'],
  },
  'auto-link': {
    path: '',
    tags: ['text', 'auto-link'],
    internalOnly: false,
    stories: ['default', 'user-mention'],
  },
  'bid-pattern-card': {
    path: '',
    tags: ['Structure'],
    internalOnly: false,
    stories: ['default'],
  },
  'chat-button': {
    path: '',
    tags: ['functional'],
    internalOnly: false,
    stories: ['default', 'responsive'],
  },
  'comment-ui': {
    path: '',
    tags: ['comments'],
    internalOnly: false,
    stories: ['default', 'active', 'badges', 'header-icon'],
  },
  experience: {
    path: '',
    tags: ['other'],
    internalOnly: false,
    stories: [
      'default',
      'background-color',
      'education',
      'linked-roles',
      'publications',
      'qualifications',
    ],
  },
  'interactive-text': {
    path: '',
    tags: ['text', 'link', 'emoji'],
    internalOnly: false,
    stories: ['default', 'max-lines'],
  },
  linkshim: {
    path: '',
    tags: ['text', 'actions', 'link', 'interactive', 'security'],
    internalOnly: false,
    stories: ['default'],
  },
  'newsfeed-system-messages': {
    path: '',
    tags: ['Structure'],
    internalOnly: false,
    stories: ['default'],
  },
  'project-contest-card': {
    path: '',
    tags: ['Structure'],
    internalOnly: false,
    stories: ['default'],
  },
  'radio-card': {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: ['default', 'borders', 'custom-content', 'show-radio'],
  },
  'review-card': {
    path: '',
    tags: ['other'],
    internalOnly: false,
    stories: ['default', 'perspective', 'reply', 'services'],
  },
  'service-offering-carousel': {
    path: '',
    tags: ['Structure'],
    internalOnly: false,
    stories: ['default', 'edit-mode'],
  },
  'showcase-card': {
    path: '',
    tags: ['cards'],
    internalOnly: false,
    stories: [
      'default',
      'aspect-ratio',
      'carousel',
      'clickable',
      'expandable-content',
      'not-image',
      'scale-on-hover',
      'showcase-card-variant',
    ],
  },
  skills: {
    path: '',
    tags: ['Freelancer', 'functional'],
    internalOnly: false,
    stories: ['default'],
  },
  'upgrade-item': {
    path: '',
    tags: ['upgrade', 'freelancer'],
    internalOnly: false,
    stories: ['default', 'free-upgrade', 'promo-price', 'variation'],
  },
  'user-feedback': {
    path: '',
    tags: ['actions'],
    internalOnly: false,
    stories: ['default'],
  },
  'user-info': {
    path: '',
    tags: ['other'],
    internalOnly: false,
    stories: ['default', 'user-avatar'],
  },
  'user-rehire-card': {
    path: '',
    tags: ['Structure'],
    internalOnly: false,
    stories: ['default'],
  },
  'user-search-card': {
    path: '',
    tags: ['Structure'],
    internalOnly: false,
    stories: ['default'],
  },
};
