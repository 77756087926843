// @generated source--
// scripts/generate-bits/file-generators/component-story-map.generator.ts

export const directiveStoryMap: {
  [name: string]: {
    path: string;
    tags: string[];
    internalOnly: boolean;
    stories: string[];
  };
} = {
  'animate-on-scroll': {
    path: '',
    tags: ['animations'],
    internalOnly: false,
    stories: ['default', 'reset'],
  },
  'auto-grow': {
    path: '',
    tags: ['textarea', 'resize'],
    internalOnly: false,
    stories: ['default'],
  },
  'control-value-accessor': {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: ['default'],
  },
  hide: {
    path: '',
    tags: ['layout', 'text'],
    internalOnly: false,
    stories: ['default'],
  },
  'long-press': {
    path: '',
    tags: ['button', 'press'],
    internalOnly: false,
    stories: ['default'],
  },
  repeat: {
    path: '',
    tags: ['layout', 'text'],
    internalOnly: false,
    stories: ['default'],
  },
  tagging: {
    path: '',
    tags: ['tag', 'text'],
    internalOnly: false,
    stories: ['default'],
  },
  visibility: {
    path: '',
    tags: ['visibility', 'event'],
    internalOnly: false,
    stories: ['default'],
  },
};
