// @generated source--
// scripts/generate-bits/file-generators/component-story-map.generator.ts

export const componentStoryMap: {
  [name: string]: {
    path: string;
    tags: string[];
    internalOnly: boolean;
    stories: string[];
  };
} = {
  'accept-rate': {
    path: '',
    tags: ['reputation'],
    internalOnly: true,
    stories: ['default', 'compact', 'size'],
  },
  'anchor-scroll': {
    path: '',
    tags: ['navigation'],
    internalOnly: true,
    stories: ['default', 'if-needed'],
  },
  'animated-svg': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: false,
    stories: ['default', 'animated-svg-list', 'loading-methods'],
  },
  'annotation-indicator': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: true,
    stories: ['default', 'no-animation'],
  },
  badge: {
    path: '',
    tags: ['reputation'],
    internalOnly: true,
    stories: [
      'default',
      'corporate',
      'exam',
      'membership',
      'preferred-freelancer',
      'recruiter',
      'rising-star',
      'sizes',
      'staff',
      'user',
      'verified',
    ],
  },
  'banner-alert': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: false,
    stories: ['default', 'bordered', 'closeable', 'compact', 'level', 'types'],
  },
  'banner-announcement': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: false,
    stories: ['default', 'buttons'],
  },
  'banner-upsell': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: true,
    stories: ['default', 'adding-button', 'adding-image', 'colors', 'sizes'],
  },
  'best-practises': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: true,
    stories: ['default'],
  },
  breadcrumbs: {
    path: '',
    tags: ['navigation'],
    internalOnly: false,
    stories: ['default', 'disabled', 'mobile-show-breadcrumb'],
  },
  button: {
    path: '',
    tags: ['buttons & actions'],
    internalOnly: false,
    stories: [
      'default',
      'button-group',
      'button-icons',
      'button-link',
      'colors',
      'example',
      'hierarchy',
      'responsive',
      'sizes',
      'states',
    ],
  },
  callout: {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: false,
    stories: [
      'default',
      'backdrop',
      'colors',
      'crawlable',
      'delay',
      'edge-to-edge',
      'hide-arrow',
      'hide-close-button',
      'hover',
      'mobile-dropdown',
      'mobile-fullscreen',
      'placement',
      'sizes',
    ],
  },
  'camera-input': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: ['default', 'custom-title-and-cta', 'trigger-type'],
  },
  'card-list': {
    path: '',
    tags: ['cards'],
    internalOnly: true,
    stories: ['default', 'select-color', 'selectable', 'sizes'],
  },
  card: {
    path: '',
    tags: ['cards'],
    internalOnly: false,
    stories: [
      'default',
      'border',
      'clickable',
      'closeable',
      'edge-to-edge',
      'expandable',
      'flex-align-items',
      'flex-direction',
      'flex-justify-content',
      'header-right',
      'levels',
      'ribbon-implementation',
      'sizes',
      'tabs-implementation',
    ],
  },
  carousel: {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: [
      'default',
      'arrow-backdrop',
      'arrow-icon',
      'arrow-position',
      'autoplay',
      'dot-indicator',
      'dot-indicator-position',
      'scroll-behaviour',
      'show-peek-slide',
    ],
  },
  chart: {
    path: '',
    tags: ['charts'],
    internalOnly: true,
    stories: ['default'],
  },
  'checkbox-card': {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: ['default', 'border', 'colors', 'disable', 'level', 'validation'],
  },
  checkbox: {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: ['default', 'disable', 'size', 'validation'],
  },
  'completed-jobs': {
    path: '',
    tags: ['reputation'],
    internalOnly: true,
    stories: ['default', 'size'],
  },
  container: {
    path: '',
    tags: ['layouts'],
    internalOnly: false,
    stories: ['default', 'mobile-padding', 'sizes'],
  },
  'countdown-timer': {
    path: '',
    tags: ['progress & loading indicators'],
    internalOnly: false,
    stories: ['default'],
  },
  'directory-item': {
    path: '',
    tags: ['navigation'],
    internalOnly: true,
    stories: ['default', 'alignment', 'arrow-colors', 'custom-content'],
  },
  'display-card': {
    path: '',
    tags: ['cards'],
    internalOnly: true,
    stories: ['default', 'entry-card-implementation', 'levels'],
  },
  drawer: {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: false,
    stories: [
      'default',
      'edge-to-edge',
      'full-width',
      'hide-close-button',
      'position',
      'sizes',
    ],
  },
  'dropdown-filter': {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: false,
    stories: [
      'default',
      'autoclose',
      'content-size',
      'disabled',
      'display',
      'edge-to-edge',
      'filter-applied',
      'full-width',
      'hide-dropdown-icon',
      'icon-label',
      'icon-only',
      'logo',
      'size',
      'text-button',
      'unread-indicator',
    ],
  },
  earnings: {
    path: '',
    tags: ['reputation'],
    internalOnly: true,
    stories: ['default', 'compact', 'flip-value', 'size'],
  },
  'emoji-picker': {
    path: '',
    tags: ['messaging / conversational UI'],
    internalOnly: true,
    stories: ['default', 'picker-size'],
  },
  'file-display': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: ['default', 'filename', 'icon-types', 'sizes', 'types'],
  },
  'file-select': {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: [
      'default',
      'alignment',
      'attachment-icon',
      'button',
      'custom-content',
      'file-type-hint',
      'note',
      'preview',
      'validation',
    ],
  },
  'file-viewer': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: false,
    stories: ['default'],
  },
  flag: {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: ['default', 'sizes'],
  },
  'floating-action': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: false,
    stories: ['default', 'colors', 'positions', 'sizes'],
  },
  focus: {
    path: '',
    tags: ['forms'],
    internalOnly: true,
    stories: ['default', 'autofocus'],
  },
  'form-save': {
    path: '',
    tags: ['forms'],
    internalOnly: true,
    stories: ['default'],
  },
  'gallery-grid': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: [
      'default',
      'aspect-ratio',
      'hide-filename',
      'max-columns',
      'max-rows',
      'reordering',
      'responsive',
      'showcase-card',
      'zoom-on-hover',
    ],
  },
  'gallery-mosaic': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: false,
    stories: [
      'default',
      'interactive-content',
      'item-spacing',
      'rounded-corners',
      'single-image-fit',
    ],
  },
  'generic-error': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: true,
    stories: ['default', 'align', 'custom-retry', 'message'],
  },
  grid: {
    path: '',
    tags: ['layouts'],
    internalOnly: false,
    stories: ['default', 'alignment', 'offsetting', 'reordering'],
  },
  heading: {
    path: '',
    tags: ['typography'],
    internalOnly: false,
    stories: [
      'default',
      'colors',
      'responsive',
      'sizes',
      'text-align',
      'text-transform',
      'truncate',
      'weights',
    ],
  },
  'highlight-text': {
    path: '',
    tags: ['typography'],
    internalOnly: true,
    stories: ['default', 'background-color'],
  },
  highlight: {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: false,
    stories: ['default', 'callout'],
  },
  hr: {
    path: '',
    tags: ['typography'],
    internalOnly: false,
    stories: ['default', 'colors', 'with-text'],
  },
  icon: {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: false,
    stories: [
      'default',
      'backdrop',
      'button',
      'click-animation',
      'colors-and-sizes',
      'disabled',
      'drop-shadow',
      'hover-animation',
      'icon-font-list',
      'icon-font-weight',
      'icon-list',
    ],
  },
  'image-caption': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: ['default', 'color'],
  },
  'image-cropper': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: false,
    stories: ['default', 'aspect-ratio'],
  },
  'infinite-scroll': {
    path: '',
    tags: ['navigation'],
    internalOnly: true,
    stories: ['default', 'page-size'],
  },
  input: {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: [
      'default',
      'align',
      'animated-placeholders',
      'button',
      'compact-date-input',
      'disabled',
      'edit-inline',
      'edit-inline-title-size',
      'hint',
      'icons',
      'keyboard-type',
      'labels',
      'max-character',
      'max-length',
      'min-value',
      'sizes',
      'states',
      'types',
      'usage',
      'validation',
    ],
  },
  label: {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: ['default'],
  },
  link: {
    path: '',
    tags: ['buttons & actions'],
    internalOnly: false,
    stories: [
      'default',
      'animated-icon',
      'capitalization',
      'colors',
      'flex',
      'highlight-colors',
      'hover-colors',
      'link-action',
      'link-icon',
      'sizes',
      'types',
    ],
  },
  list: {
    path: '',
    tags: ['layouts'],
    internalOnly: true,
    stories: [
      'default',
      'bordered',
      'checkbox',
      'clickable',
      'dismiss',
      'expandable',
      'horizontal-padding',
      'keyboard-support',
      'ordered-unordered',
      'padding',
      'radio',
      'selectable',
      'selected',
      'toggle',
      'transparent',
    ],
  },
  'loading-text': {
    path: '',
    tags: ['progress & loading indicators'],
    internalOnly: false,
    stories: ['default'],
  },
  'location-input': {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: [
      'default',
      'detect-location',
      'disabled',
      'display-postal-code',
      'set-value',
    ],
  },
  logo: {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: ['default', 'background-color', 'sizes'],
  },
  map: {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: [
      'default',
      'custom-markers',
      'hide-landmarks',
      'interactive',
      'sizes',
      'tooltip',
    ],
  },
  'media-viewer': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: [
      'default',
      'adding-caption',
      'download-gallery-item',
      'enabling-zoom-click-animation',
      'inline-mode',
      'media-types',
      'modal-mode',
      'photo-rotations',
    ],
  },
  modal: {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: false,
    stories: [
      'default',
      'closeable',
      'colors',
      'edge-to-edge',
      'mobile-custom-view-header',
      'mobile-fullscreen',
      'sizes',
    ],
  },
  'more-options': {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: false,
    stories: ['default', 'colors', 'icon-type', 'mobile-dropdown', 'sizes'],
  },
  'multi-select': {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: [
      'default',
      'adding-text',
      'allow-input-on-blur',
      'counter',
      'dropdown-item-select-color',
      'hide-input-on-non-empty',
      'invalid-inputs',
      'items',
      'max-length',
      'max-rows',
      'options',
      'secondary-placeholder',
      'show-highlight-initially',
      'show-plus-icon',
      'validation',
    ],
  },
  'multiple-location-input': {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: ['default', 'sizes'],
  },
  'new-feature-tag': {
    path: '',
    tags: ['tags'],
    internalOnly: true,
    stories: ['default'],
  },
  'online-indicator': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: true,
    stories: ['default', 'border', 'responsive', 'sizes'],
  },
  'page-layout': {
    path: '',
    tags: ['layouts'],
    internalOnly: false,
    stories: [
      'default',
      'min-height',
      'page-size',
      'secondary',
      'split-extend',
    ],
  },
  pagination: {
    path: '',
    tags: ['navigation'],
    internalOnly: false,
    stories: ['default', 'compact', 'extremeties', 'mobile-compact', 'outline'],
  },
  picture: {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: [
      'default',
      'aspect-ratio-value',
      'background-image',
      'bounded-height',
      'bounded-width',
      'fallback',
      'fixed-aspect-ratio',
      'full-width',
      'image-sources',
      'lazy-load',
      'object-fit',
      'preload-image',
      'width-and-height',
      'zoom-on-hover',
    ],
  },
  'progress-bar': {
    path: '',
    tags: ['progress & loading indicators'],
    internalOnly: false,
    stories: [
      'default',
      'checkpoints',
      'colors',
      'rounded-corners',
      'secondary-progress',
      'show-progress-marker',
      'sizes',
      'vertical-orientation',
    ],
  },
  'progress-steps': {
    path: '',
    tags: ['progress & loading indicators'],
    internalOnly: false,
    stories: [
      'default',
      'center-edge-text',
      'changing-orientation',
      'clickable-steps',
      'hide-callout',
      'mobile-large-indicator',
      'skipped-steps',
      'start-step',
      'substep',
      'type',
      'vertical-orientation',
    ],
  },
  'pull-refresh': {
    path: '',
    tags: ['buttons & actions'],
    internalOnly: false,
    stories: ['default', 'drawer-type', 'scroll-basis', 'spinner-type'],
  },
  radio: {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: ['default', 'disable', 'size', 'validation'],
  },
  rating: {
    path: '',
    tags: ['reputation'],
    internalOnly: true,
    stories: [
      'default',
      'background-color',
      'compact',
      'half-rating',
      'inline',
      'read-only',
      'review-count',
      'size',
      'ticks-color',
      'type',
      'validation',
    ],
  },
  'relative-time': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: true,
    stories: [
      'default',
      'color',
      'include-seconds',
      'input-types',
      'responsive',
      'secondary-unit',
      'size',
      'static',
      'strict',
      'suffix',
      'time-passed',
      'weight',
    ],
  },
  'review-count': {
    path: '',
    tags: ['reputation'],
    internalOnly: true,
    stories: ['default', 'size'],
  },
  ribbon: {
    path: '',
    tags: ['reputation'],
    internalOnly: true,
    stories: ['default', 'colors', 'icon', 'placement'],
  },
  search: {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: [
      'default',
      'borderless',
      'expandable',
      'round-corners',
      'with-custom-control',
      'with-scrollable-search-results',
      'without-result-display',
    ],
  },
  'section-article': {
    path: '',
    tags: ['layouts (landing pages)'],
    internalOnly: true,
    stories: ['default', 'alignment', 'button', 'colors', 'sizes', 'title'],
  },
  'section-case-study-carousel': {
    path: '',
    tags: ['layouts (landing pages)'],
    internalOnly: true,
    stories: ['default', 'caption', 'footer', 'header-detail', 'quote'],
  },
  'section-companies': {
    path: '',
    tags: ['layouts (landing pages)'],
    internalOnly: true,
    stories: ['default', 'colors', 'custom-title', 'logos-list'],
  },
  'section-feature': {
    path: '',
    tags: ['layouts (landing pages)'],
    internalOnly: true,
    stories: [
      'default',
      'alignment',
      'border-top',
      'dark-background',
      'full-width',
      'image-overflow',
      'overlay',
      'sizes',
    ],
  },
  'section-gallery': {
    path: '',
    tags: ['layouts (landing pages)'],
    internalOnly: true,
    stories: ['default', 'dot-indicator', 'image-caption', 'thumbnails'],
  },
  'section-hero': {
    path: '',
    tags: ['layouts (landing pages)'],
    internalOnly: true,
    stories: [
      'default',
      'alignment',
      'colors',
      'content-width',
      'detail-text',
      'height',
      'image-detail',
      'video-background',
    ],
  },
  select: {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: [
      'default',
      'disabled',
      'flags',
      'grouped-options',
      'key-value-object',
      'sizes',
      'validation',
    ],
  },
  'share-buttons': {
    path: '',
    tags: ['buttons & actions'],
    internalOnly: false,
    stories: ['default', 'shareicons', 'showtext', 'sizes'],
  },
  'share-link': {
    path: '',
    tags: ['buttons & actions'],
    internalOnly: false,
    stories: ['default'],
  },
  'share-modal': {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: true,
    stories: ['default'],
  },
  slider: {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: ['default', 'double', 'label-position'],
  },
  'smart-textarea': {
    path: '',
    tags: ['forms', 'popups / overlays'],
    internalOnly: false,
    stories: ['default', 'callout-positioning', 'callout-visibility'],
  },
  'social-buttons': {
    path: '',
    tags: ['buttons & actions'],
    internalOnly: false,
    stories: ['default', 'colors', 'fluid', 'share-buttons', 'sizes'],
  },
  spinner: {
    path: '',
    tags: ['progress & loading indicators'],
    internalOnly: false,
    stories: ['default', 'bird', 'overlay', 'sizes-and-colors'],
  },
  'split-button': {
    path: '',
    tags: ['buttons & actions'],
    internalOnly: false,
    stories: [
      'default',
      'colors',
      'full-width',
      'icons',
      'links',
      'responsive',
      'sizes',
      'states',
    ],
  },
  'sticky-footer': {
    path: '',
    tags: ['layouts'],
    internalOnly: true,
    stories: ['default', 'fixed'],
  },
  table: {
    path: '',
    tags: ['layouts'],
    internalOnly: false,
    stories: [
      'default',
      'edge-to-edge',
      'expandable',
      'expandable-mobile',
      'fixed-layout',
      'header-color',
      'highlight',
      'hover',
      'mobile-view-compact',
      'mobile-view-shading',
      'no-border',
      'padding-size',
      'pagination',
      'pagination-position',
      'responsive',
      'row-links',
      'row-track-by',
      'sortable',
      'text-align',
      'title-callout',
      'vertical-alignment',
    ],
  },
  tabs: {
    path: '',
    tags: ['navigation'],
    internalOnly: false,
    stories: [
      'default',
      'alignment',
      'bordered',
      'colors',
      'direction',
      'icon',
      'image',
      'more-options',
      'router',
      'router-with-query',
      'size',
      'subtitle',
      'truncate',
    ],
  },
  'tag-status': {
    path: '',
    tags: ['tags'],
    internalOnly: true,
    stories: [
      'default',
      'background-color',
      'colors',
      'icon',
      'sizes',
      'usage',
    ],
  },
  tag: {
    path: '',
    tags: ['tags'],
    internalOnly: false,
    stories: [
      'default',
      'borderless',
      'colors',
      'icons',
      'link',
      'max-lines',
      'selectable',
      'sizes',
      'types',
    ],
  },
  'tailwind-text': {
    path: '',
    tags: ['typography'],
    internalOnly: true,
    stories: [
      'default',
      'colors',
      'max-lines',
      'responsive',
      'sizes',
      'weights',
    ],
  },
  text: {
    path: '',
    tags: ['typography'],
    internalOnly: true,
    stories: [
      'default',
      'colors',
      'display-line-breaks',
      'highlight-colors',
      'read-more',
      'responsive',
      'sizes',
      'styles',
      'text-align',
      'text-decoration',
      'text-transform',
      'types',
      'weights',
    ],
  },
  textarea: {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: [
      'default',
      'auto-grow',
      'borderless',
      'clear-button',
      'disabled',
      'display-character-count',
      'events',
      'max-character',
      'max-height',
      'max-length',
      'validation',
    ],
  },
  'thumbnail-viewer': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: ['default', 'group-scrolling', 'hide-arrows'],
  },
  'title-card': {
    path: '',
    tags: ['Structure'],
    internalOnly: false,
    stories: ['default'],
  },
  'toast-alert': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: false,
    stories: ['default', 'closeable', 'colors', 'dynamic', 'types'],
  },
  'toggle-buttons': {
    path: '',
    tags: ['buttons & actions'],
    internalOnly: false,
    stories: ['default', 'colors', 'full-width', 'icons', 'size'],
  },
  toggle: {
    path: '',
    tags: ['forms'],
    internalOnly: false,
    stories: ['default', 'colors', 'sizes', 'states'],
  },
  tooltip: {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: false,
    stories: ['default'],
  },
  'unread-indicator': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: true,
    stories: ['default', 'border', 'color', 'counter', 'position', 'sizes'],
  },
  'upgrade-tag': {
    path: '',
    tags: ['tags'],
    internalOnly: true,
    stories: ['default', 'sizes', 'upgrade-types'],
  },
  'user-avatar': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: [
      'default',
      'group',
      'letter',
      'loading',
      'online-status',
      'responsive',
      'sizes',
      'video-avatar',
    ],
  },
  'user-card-carousel': {
    path: '',
    tags: ['layouts (landing pages)'],
    internalOnly: true,
    stories: ['default'],
  },
  username: {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: [
      'default',
      'badge',
      'colors',
      'compact',
      'flag',
      'sizes',
      'truncate-text',
      'with-link',
    ],
  },
  'validation-error': {
    path: '',
    tags: ['forms'],
    internalOnly: true,
    stories: ['default'],
  },
  'video-modal': {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: true,
    stories: ['default'],
  },
  'video-record-input': {
    path: '',
    tags: ['popups / overlays'],
    internalOnly: true,
    stories: [
      'default',
      'custom-content-while-recording',
      'custom-copies',
      'custom-subcontent',
      'face-filter',
    ],
  },
  'video-record-modal': {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: ['default'],
  },
  video: {
    path: '',
    tags: ['icons / images / media'],
    internalOnly: true,
    stories: [
      'default',
      'auto-hide-controls',
      'auto-play',
      'auto-play-on-hover',
      'disable-controls',
      'object-fit',
      'playback-speed',
      'time-display',
    ],
  },
  'view-header': {
    path: '',
    tags: ['navigation'],
    internalOnly: true,
    stories: ['default', 'backgrounds', 'show-back-button'],
  },
  widget: {
    path: '',
    tags: ['cards'],
    internalOnly: false,
    stories: ['default'],
  },
  'worked-time': {
    path: '',
    tags: ['alerts & notifications'],
    internalOnly: true,
    stories: ['default', 'size'],
  },
};
